import { debounce } from './util'

const suggestionsClass = 'autocomplete-suggestions'
const suggestionClass = 'autocomplete-suggestion'
const highlightClass = 'autocomplete-suggestion-highlight'
const separatorClass = 'autocomplete-suggestion-separator'

const KEYCODE_ARROWUP = 38
const KEYCODE_ARROWDOWN = 40
const KEYCODE_ENTER = 13
const KEYCODE_ESCAPE = 27

const defaultOptions = {
  onFocusIn: false,
  clearOnSelect: true,
  focusAfterSelect: true,
  sort: true,
  onEnter: null,
  delay: 1000,
}

/**
 * Creates a suitable autocomplete input into the specified element and
 * returns a reference to it.
 *
 * @param element
 * @param placeholder
 * @returns {HTMLInputElement}
 */
export function addAutocompleteInput(element, placeholder) {
  const autocompleteInput = document.createElement('input')
  autocompleteInput.placeholder = placeholder
  element.appendChild(autocompleteInput)
  return autocompleteInput
}

function findHighlight(suggestions, callback) {
  for (let i = 0; i < suggestions.children.length; i = i + 1) {
    const suggestion = suggestions.children[i]
    if (suggestion.classList.contains(highlightClass)) {
      callback(suggestion, i)
      break
    }
  }
}

function findValidSuggestionBackward(suggestions, start, callback) {
  for (let i = start - 1; i >= 0; i = i - 1) {
    const suggestion = suggestions.children[i]
    if (!suggestion.classList.contains(separatorClass)) {
      callback(suggestion)
      break
    }
  }
}

function findValidSuggestionForward(suggestions, start, callback) {
  for (let i = start + 1; i < suggestions.children.length; i = i + 1) {
    const suggestion = suggestions.children[i]
    if (!suggestion.classList.contains(separatorClass)) {
      callback(suggestion)
      break
    }
  }
}

/**
 * Creates a basic URL-based Data Source
 *
 * @param {string} url The URL to query, must accept a "term" query param
 * @return {function} A function that returns a Promise that resolves to a list of {id, text, ?separator} objects
 */
export function urlDataSource(url) {
  return async (term) => {
    if (!term) {
      return []
    }
    const res = await fetch(`${url}?term=${term}`)

    return res.json()
  }
}

/**
 * Adds autocompletion capabilities to an input element.
 *
 * @param {Element} input The input to attach the autocompletion module to
 * @param {function} dataSource A function that returns a Promise that resolves to a list of {id, text, ?separator} objects
 * @param {function} onSelect A callback that takes a {id, text, ?separator, ?extra} object as a param
 * @param {object} options Additional options
 */
export function addAutocomplete(
  input,
  dataSource,
  onSelect,
  options = defaultOptions
) {
  const suggestions = document.createElement('div')
  suggestions.classList.add(suggestionsClass)
  const focusout = function () {
    suggestions.innerHTML = ''
    document.removeEventListener('click', focusout)
  }
  if (input.id == 'header-search') {
    input.parentNode.parentNode.insertBefore(suggestions, input.parentNode)
    input.parentNode.parentNode.classList.add('position-relative')
  } else {
    const wrapper = document.createElement('div')
    wrapper.classList.add('position-relative')
    wrapper.classList.add('d-flex')
    wrapper.classList.add('flew-grow-1')
    input.parentNode.insertBefore(wrapper, input)

    wrapper.appendChild(input)
    wrapper.appendChild(suggestions)
  }

  input.classList.add('form-control')
  input.addEventListener('keydown', function (e) {
    switch (e.which) {
      case KEYCODE_ARROWUP:
        findHighlight(suggestions, (suggestion, i) => {
          findValidSuggestionBackward(suggestions, i, (found) => {
            suggestion.classList.remove(highlightClass)
            found.classList.add(highlightClass)
            input.value = found.innerText

            suggestions.dataset.selectedText = found.dataset.text
            suggestions.dataset.selectedId = found.dataset.id
            suggestions.dataset.selectedExtra = JSON.stringify(
              found.dataset.extra
            )
          })
        })
        break
      case KEYCODE_ARROWDOWN:
        findHighlight(suggestions, (suggestion, i) => {
          findValidSuggestionForward(suggestions, i, (found) => {
            suggestion.classList.remove(highlightClass)
            found.classList.add(highlightClass)
            input.value = found.innerText

            suggestions.dataset.selectedText = found.dataset.text
            suggestions.dataset.selectedId = found.dataset.id
            suggestions.dataset.selectedExtra = JSON.stringify(
              found.dataset.extra
            )
          })
        })
        break
      case KEYCODE_ENTER:
        e.preventDefault()
        if (options.onEnter) {
          options.onEnter()
          break
        }
        const highlight = suggestions.querySelector(`.${highlightClass}`)
        if (highlight) {
          suggestions.innerHTML = ''
          input.value = highlight.dataset.text
          if (options.clearOnSelect) {
            input.value = ''
          }
          document.removeEventListener('click', focusout)
          onSelect({
            id: highlight.dataset.id,
            text: highlight.dataset.text,
            extra: JSON.parse(highlight.dataset.extra),
          })
        }
        break
      case KEYCODE_ESCAPE:
        suggestions.innerHTML = ''
        suggestions.dataset.selectedId = null
        suggestions.dataset.selectedText = null
        suggestions.dataset.selectedExtra = null
        document.removeEventListener('click', focusout)
        break
    }
  })
  const fetchSuggestions = (e) => {
    dataSource(e.target.value).then(function (results) {
      if (typeof results.results !== 'undefined') {
        results = results.results
      }
      let isFirst = true
      // focusin triggers click immediately, so we need to delay attaching the event to avoid closing the suggestions immediately
      setTimeout(() => {
        document.addEventListener('click', focusout)
      }, 100)
      suggestions.innerHTML = ''

      if (options.sort) {
        results.sort((a, b) => a.text.localeCompare(b.text))
      }

      for (const result of results) {
        const suggestion = document.createElement('div')
        suggestion.innerHTML = result.text
        suggestion.dataset.text = result.text
        suggestion.dataset.id = result.id
        suggestion.dataset.extra = result.extra
          ? JSON.stringify(result.extra)
          : null
        suggestion.classList.add(suggestionClass)
        if (result.separator) {
          suggestion.classList.add(separatorClass)
        }
        if (isFirst && !result.separator) {
          isFirst = false
          suggestion.classList.add(highlightClass)
          suggestions.dataset.selectedId = result.id
          suggestions.dataset.selectedText = result.text
          suggestions.dataset.selectedExtra = JSON.stringify(result.extra)
        }
        if (!result.separator) {
          suggestion.addEventListener('mouseenter', function (e) {
            const highlight = suggestions.querySelector(`.${highlightClass}`)
            highlight.classList.remove(highlightClass)
            e.target.classList.add(highlightClass)
            suggestions.dataset.selectedText = e.target.dataset.text
            suggestions.dataset.selectedId = e.target.dataset.id
            suggestions.dataset.selectedExtra = JSON.stringify(
              e.target.dataset.extra
            )
          })
          suggestion.addEventListener('click', function () {
            suggestions.innerHTML = ''
            if (options.clearOnSelect) {
              input.value = ''
            } else {
              input.value = result.text
            }
            // todo: figure out why onfocus doesn't get the right e.target.value (maybe it's just 2fas?)
            if (!options.onFocusIn || options.focusAfterSelect) {
              input.focus()
            }
            document.removeEventListener('click', focusout)
            onSelect(result)
          })
        }
        suggestions.appendChild(suggestion)
      }
    })
  }

  const waitForSuggestions = () => {
    suggestions.innerHTML = ''
    const suggestion = document.createElement('div')
    suggestion.innerHTML = window.translations['select2_searching']
    suggestion.classList.add(suggestionClass)
    suggestions.appendChild(suggestion)
  }

  if (options.onFocusIn) {
    input.addEventListener('focusin', fetchSuggestions)
  }
  input.addEventListener(
    'input',
    debounce(
      fetchSuggestions,
      options.delay || defaultOptions.delay,
      waitForSuggestions
    )
  )

  return input
}
